import React from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../../../components/Layout"
import { Banner } from "../../../components/halvabonus/Banner"
import { HabenskiyFund } from "../../../components/halvabonus/HabenskiyFund"
import { Registration } from "../../../components/halvabonus/Registration"
import { Footer } from "../../../components/halvabonus/Footer/Habenskiy"
import { PageData } from "../../../interfaces/pageProps"
import { getPageData } from "../../../helpers/getPageData"

export default function Page({ data }: PageProps<PageData>) {
  const { seoData, ligal } = getPageData(data)
  return (
    <Layout seoData={seoData} shortText={ligal.shortText} hasCustomFooter>
      <Banner
        title="Помогаем детям вместе"
        subTitle="Благотворительный фонд Совкомбанка"
        hasHabenskiy
      />
      <HabenskiyFund />
      <Registration link="https://sovcombank.ru/document?id=8633" />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    pageDataJson(pageName: { eq: "fond-Habenskogo" }) {
      seoData {
        title
        description
      }
    }
  }
`
