import React from "react"
import Container from "@ecom/ui/components/Container"

import * as styles from "./footer.module.scss"

import logo from "./img/logo.svg"

export function Footer() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <p>C уважением и заботой, Ваш</p>
        <img src={logo} alt="logo" />
      </Container>
    </section>
  )
}
