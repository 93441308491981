import React from "react"
import clsx from "clsx"
import Container from "@ecom/ui/components/Container"

import * as styles from "./habenskiyfund.module.scss"

export function HabenskiyFund() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.title}>Фонд Константина Хабенского</div>
        <div className={styles.text}>
          <p>
            Фонд Константина Хабенского с 2008 года помогает детям справиться с действительно
            сложными заболеваниями и сохранить жизнь. Благотворительный фонд оказывает финансовую
            поддержку при обследовании и лечении онкологических, а также других болезней головного и
            спинного мозга.
          </p>
          <p>
            Вы можете принять участие в этой доброй миссии, для этого нужно только пользоваться уже
            привычной для вас картой «Халва».
          </p>
        </div>
        <div className={styles.title} style={{ marginBottom: 24 }}>
          Всe просто:
        </div>
        <div className={clsx(styles.text, styles.bottomText)}>
          Вы выполняете правило «5х10» в отчетном периоде и получаете все бонусы программы, а Банк
          за Вас перечисляет в фонд 200 рублей.
        </div>
      </Container>
    </section>
  )
}
